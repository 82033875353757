import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./ModalPayment.module.css";
import { postFinalizeWithThreeDSecure } from "apiv1/project";
import PriceCard from "./PriceCard";
import ShareButtons from "./ShareButtons";
import Modal from "components/Modal";
import { useLocation } from "react-router-dom";
import ModalGiftUrl from "stories/components/Modal/ModalGiftUrl/ModalGiftUrl";
import LoadingFullWindow from "components/LoadingFullWindow";
import axios from "axios";

type ModalPaymentSuccessProps = {
  projectId: string;
  projectName: string;
  thumbnail: string | null;
  categoryName: string;
  dispatchReloadProject: () => void;
};

const ModalPaymentSuccess = ({
  projectId,
  projectName,
  thumbnail,
  categoryName,
  dispatchReloadProject,
}: ModalPaymentSuccessProps) => {
  const {
    isOpen,
    isLoading,
    finalizedSession,
    errorCode,
    closeModal,
    clearErrorCode,
  } = useModalPaymentSuccess(projectId, dispatchReloadProject);

  if (isLoading) {
    return <LoadingFullWindow label="決済情報を取得中" />;
  }

  if (errorCode) {
    return (
      <Modal open={true} onClose={clearErrorCode}>
        <ModalThreeDSecureError />
      </Modal>
    );
  }

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <ModalPaymentBody
        projectName={projectName}
        thumbnail={thumbnail}
        categoryName={categoryName}
        price={finalizedSession?.price ?? 0}
        basePrice={finalizedSession?.priceBeforeDiscount ?? 0}
        giftId={finalizedSession?.giftId}
        onClose={closeModal}
      />
    </Modal>
  );
};

type ModalPaymentBodyProps = {
  projectName: string;
  thumbnail: string | null;
  categoryName: string;
  price: number;
  basePrice: number;
  giftId: string | undefined;
  onClose: () => void;
};

const ModalPaymentBody: React.VFC<ModalPaymentBodyProps> = ({
  projectName,
  thumbnail,
  categoryName,
  price,
  basePrice,
  giftId,
  onClose,
}) => {
  if (giftId) {
    return (
      <ModalGiftUrl
        title={projectName}
        category={categoryName}
        giftCode={giftId}
        orderedAt={new Date()}
        thumbnail={thumbnail}
      />
    );
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>クレジットカード決済</h2>
      <div className={styles.box}>
        <PriceCard
          projectName={projectName}
          categoryName={categoryName}
          price={price}
          priceBeforeDiscount={basePrice === price ? undefined : basePrice}
        />
        <p className={styles.txt}>
          購入が完了しました。
          <br className={styles.spi} />
          ご利用ありがとうございました。
        </p>
        <ShareButtons projectName={projectName} />
        <button className={styles.btnSecondary} onClick={onClose}>
          決済画面を閉じる
        </button>
      </div>
    </div>
  );
};

const ModalThreeDSecureError = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>決済エラー</h2>
      <div className={styles.box}>
        <p className={styles.txt}>
          クレジットカードの3Dセキュア認証に失敗したため、
          <br className={styles.spi} />
          決済が中止されました。
          <br />
          クレジットカードやメールアドレスの入力内容に誤りがないか、ご確認ください。
        </p>
      </div>
    </div>
  );
};

const useModalPaymentSuccess = (
  projectId: string,
  dispatchReloadProject: () => void
) => {
  const location = useLocation();
  const sessionId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("session");
  }, [location.search]);

  const [finalizedSession, setFinalizedSession] = useState<{
    price: number;
    priceBeforeDiscount: number;
    giftId?: string;
  } | null>(null);

  const [state, setState] = useState<
    "static" | "loading" | "open" | "finished"
  >("static");
  const [errorCode, setErrorCode] = useState<string | null>(null);

  useEffect(() => {
    if (sessionId != null && state === "static") {
      setState("loading");
      postFinalizeWithThreeDSecure(projectId, { sessionId })
        .then((res) => {
          setFinalizedSession(res.data);
          setState("open");
          dispatchReloadProject();
          const url = new URL(window.location.href);
          url.searchParams.delete("session");

          window.history.replaceState(null, "", url.toString());
        })
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            const data = err.response?.data;
            if (hasErrorCode(data)) {
              setErrorCode(data.errorCode);
            }
          }
          setState("finished");
        });
    }
  }, [state, projectId, sessionId, setState, dispatchReloadProject]);

  const closeModal = useCallback(() => {
    setState("finished");
  }, [setState]);

  const clearErrorCode = useCallback(() => {
    setErrorCode(null);
  }, [setErrorCode]);

  return {
    isOpen: state === "open",
    isLoading: state === "loading",
    finalizedSession,
    errorCode,
    closeModal,
    clearErrorCode,
  };
};

interface HasErrorCode {
  errorCode: string;
}

const hasErrorCode = (x: unknown): x is HasErrorCode => {
  if (x == null || typeof x !== "object") {
    return false;
  }

  const { errorCode } = x as any;
  return typeof errorCode === "string";
};

export default ModalPaymentSuccess;
