import Spinner from "components/Spinner";
import styles from "./LoadingFullWindow.module.css";
import { createPortal } from "react-dom";

type LoadingFullWindowProps = {
  label: string;
};

const LoadingFullWindow = ({ label }: LoadingFullWindowProps) => {
  const container = document.getElementById("modal-root");
  if (container == null) {
    return null;
  }

  return createPortal(
    <div className={styles.backdrop}>
      <div className={styles.body}>
        <Spinner />
        <p className={styles.label}>{label}</p>
      </div>
    </div>,
    container
  );
};

export default LoadingFullWindow;
