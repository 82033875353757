import { withIdToken } from "apiv1";
import axios from "axios";

export interface Tenant {
  id: string;
  bank_account_number: string;
  bank_branch_code: string;
  bank_code: string;
  bank_account_holder_name: string;
  bank_account_type: string;
  bank_account_status: "pending" | "success" | "failed";
}

export type PayoutBankAccount = {
  bank_code: string;
  bank_branch_code: string;
  bank_account_type: string;
  bank_account_number: string;
  bank_account_holder_name: string;
};

export const getPayjpTenant = async () => {
  return axios.get<Tenant>(`/v1/payjp/tenant`, await withIdToken());
};

export const getPayjpTenantExist = async () => {
  return axios.get<void>(`/v1/payjp/tenant/exist`, await withIdToken());
};

export const putPayjpTenant = async (params: PayoutBankAccount) => {
  return axios.put<void>(`/v1/payjp/tenant`, params, await withIdToken());
};

export type TransferStatus =
  | "pending"
  | "paid"
  | "failed"
  | "stop"
  | "carried_over"
  | "recombination";

export type TransferInfo = {
  amount: number; // 当月売上分の利益
  carried_balance: number | null; // 繰越金
  charge_fee: number; // 支払い手数料
  charge_gross: number; // 総売上
  dispute_amount: number; // チャージバック
  total_platform_fee: number; // プラットフォーム支払い手数料
  scheduled_date: string;
  status: TransferStatus;
  term_end: string;
  term_start: string;
  transfer_amount: number | null; // 実際に振り込まれた金額
  transfer_date: string | null;
};

export const getPayjpTransfers = async () => {
  return axios.get<TransferInfo[]>(
    `/v1/payjp/tenant/transfers`,
    await withIdToken()
  );
};

export type BalanceInfo = {
  net: number;
  state: "collecting" | "transfer" | "claim";
  closed: boolean;
  dueDate: string | null;
  statements: Statement[];
};

export type Statement = {
  net: number;
  type: "sales" | "service_fee" | "transfer_fee" | "misc";
  details: {
    grossSales: number; // 売り上げ
    fee: number; // 決済手数料 + プラットフォーム手数料
    refundFee: number; // 返金
    chargeback: number; // チャージバック
    transferFee: number; // 振り込み手数料
    other: number;
  };
  startAt: string | null;
  endAt: string | null;
};

export const getPayjpBalances = async () => {
  return axios.get<BalanceInfo[]>(
    `/v1/payjp/tenant/balances`,
    await withIdToken()
  );
};

export type MaskedCard = {
  id: string;
  last4: string;
  brand: string;
  exp_year: number;
  exp_month: number;
  fingerprint: string;
  hasEmail: boolean;
  hasName: boolean;
};

export type CustomerInfo = {
  customerId: string;
  cards: MaskedCard[];
};

export const getPayjpCustomer = async () => {
  return axios.get<CustomerInfo>("/v1/payjp/customer", await withIdToken());
};

export const postPayjpCustomer = async (cardToken: string) => {
  return axios.post<CustomerInfo>(
    "/v1/payjp/customer",
    { cardToken },
    await withIdToken()
  );
};

export const postPayjpCustomerCard = async (cardToken: string) => {
  return axios.post<void>(
    "/v1/payjp/customer/card",
    { cardToken },
    await withIdToken()
  );
};

type PatchCardParams = {
  name: string;
  email: string;
};

export const patchPayjpCustomerCard = async (
  cardId: string,
  { name, email }: PatchCardParams
) => {
  return axios.patch<void>(
    `/v1/payjp/customer/card/${cardId}`,
    { name, email },
    await withIdToken()
  );
};

export const deletePayjpCustomerCard = async (cardId: string) => {
  return axios.delete<void>(
    `/v1/payjp/customer/card/${cardId}`,
    await withIdToken()
  );
};
