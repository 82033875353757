import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./ModalPayment.module.css";
import IconVisa from "./IconVisa.png";
import IconMaster from "./IconMaster.png";
import IconJCB from "./IconJCB.png";
import IconAmex from "./IconAmex.png";
import IconDiscover from "./IconDiscover.png";
import {
  FeeType,
  postBuyGiftWithThreeDSecure,
  postBuyWithThreeDSecure,
  postGetByCoupon,
} from "apiv1/project";
import { auth, payjp } from "initialize";
import BtnWithProcessing from "components/Btn/BtnWithProcessing";
import axios from "axios";
import {
  CustomerInfo,
  deletePayjpCustomerCard,
  getPayjpCustomer,
  MaskedCard,
  patchPayjpCustomerCard,
  postPayjpCustomer,
  postPayjpCustomerCard,
} from "apiv1/payjp";
import { Coupon } from "apiv1/coupons";
import { priceWithCoupon } from "utils/coupon";
import PriceCard from "./PriceCard";
import CouponArea from "./CouponArea";
import SelectCard from "./SelectCard";
import ShareButtons from "./ShareButtons";
import Modal from "components/Modal";
import { PAYJP_API_PUBLIC_KEY } from "config/payjp";

const payjpElementOptions = {
  style: {
    base: {
      fontSize: "14px",
    },
  },
};

const elements = payjp.elements();
const numberElement = elements.create("cardNumber", payjpElementOptions);
const expiryElement = elements.create("cardExpiry", payjpElementOptions);
const cvcElement = elements.create("cardCvc", payjpElementOptions);

const AVAILABLE_CARD_BLANDS = [
  "Visa",
  "MasterCard",
  "JCB",
  "American Express",
  "Discover",
];

const ERROR_REQUIRED_NAME_AND_EMAIL =
  "カードの名義人とメールアドレスを入力してください。";

const ERROR_CARD_IS_ALREADY_REGISTERD =
  "すでに登録されているカードが入力されました。\nカード一覧からカードを選択して決済してください。";

const ERROR_UNSUPPORTED_CARD_BLAND =
  "TALTOで現在利用できないカードが入力されました。\n現在利用できるのはVisa、Mastercard、JCB、American Express、Discoverです。";

const ERROR_CREATE_TOKEN =
  "カード情報に誤りがあります。\n入力いただいた内容に間違いがないかご確認ください。";

const ERROR_PRICE_CONFLICT =
  "シナリオの価格が変更されたため、処理を中断しました。\nページをリロードして新しい価格をご確認ください。";

const ERROR_UNKNOWN =
  "決済の処理に失敗しました。\n入力いただいた内容に間違いがないかご確認ください。";

function implementsPayjpToken(arg: any): arg is PayjpToken {
  return arg !== null && typeof arg === "object" && arg.object === "token";
}

const redirectToThreeDSecure = (chargeId: string, backUrlJws: string) => {
  const url = new URL(`/v1/tds/${chargeId}/start`, "https://api.pay.jp");
  url.searchParams.append("publickey", PAYJP_API_PUBLIC_KEY);
  url.searchParams.append("back_url", backUrlJws);

  window.location.href = url.toString();
};

const cardExpiryToString = (card: MaskedCard): string => {
  const month = card.exp_month.toString().padStart(2, "0");
  const year = (card.exp_year % 100).toString().padStart(2, "0");
  return `${month}/${year}`;
};

type ModalPaymentProps = {
  purchased: boolean;
  open: boolean;
} & Omit<ModalPaymentBodyProps, "success" | "setSuccess">;

const ModalPayment: React.VFC<ModalPaymentProps> = ({
  purchased,
  open,
  onClose,
  ...props
}) => {
  const [success, setSuccess] = useState(false);

  if (props.feeType === "free") {
    return null;
  }

  if (purchased && !open) {
    return null;
  }

  const handleClose = () => {
    onClose();
    setSuccess(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalPaymentBody
        {...props}
        onClose={handleClose}
        success={success}
        setSuccess={setSuccess}
      />
    </Modal>
  );
};

type ModalPaymentBodyProps = {
  gift: boolean;
  projectId: string;
  projectName: string;
  categoryName: string;
  price: number;
  feeType: Omit<FeeType, "free">;
  success: boolean;
  setSuccess: (x: boolean) => void;
  dispatchReload: () => void;
  onClose: () => void;
};

const ModalPaymentBody: React.VFC<ModalPaymentBodyProps> = ({
  gift,
  projectId,
  projectName,
  categoryName,
  price,
  success,
  setSuccess,
  dispatchReload,
  onClose,
}) => {
  const [cardId, setCardId] = useState<"new" | string>("new");
  const [customer, setCustomer] = useState<CustomerInfo>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [coupon, setCoupon] = useState<Coupon>();
  const [dirtyCouponInput, setDirtyCouponInput] = useState(false);

  const refCardEmailInput = useRef<HTMLInputElement>(null);
  const refCardNameInput = useRef<HTMLInputElement>(null);

  const refUpdateCardEmailInput = useRef<HTMLInputElement>(null);
  const refUpdateCardNameInput = useRef<HTMLInputElement>(null);

  const defaultEmail = auth.currentUser?.email ?? "";

  const cards = customer?.cards;
  const selectedCard = useMemo(() => {
    if (!cardId || !cards) {
      return undefined;
    }

    return cards.find((card) => card.id === cardId);
  }, [cardId, cards]);
  const displayNameAndEmailForm =
    selectedCard && (!selectedCard.hasEmail || !selectedCard.hasName);

  useEffect(() => {
    getPayjpCustomer()
      .then((res) => {
        setCustomer(res.data);
        if (res.data.cards.length > 0) {
          setCardId(res.data.cards[0].id);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (cardId === "new") {
      setTimeout(() => {
        numberElement.mount("#numberElement");
        expiryElement.mount("#expiryElement");
        cvcElement.mount("#cvcElement");
      }, 0);
    }
  }, [projectId, cardId]);

  useEffect(() => {
    if (gift) {
      setCoupon(undefined);
    }
  }, [gift, setCoupon, coupon]);

  const handleBuy = async () => {
    if (dirtyCouponInput) {
      if (
        !window.confirm(
          "入力されたシナチケが適用されていません。このまま決済を行いますか？"
        )
      ) {
        return;
      }
    }

    let newCustomerId: string | undefined;
    try {
      let targetCardId = cardId;
      if (targetCardId === "new") {
        if (
          refCardEmailInput.current == null ||
          refCardNameInput.current == null
        ) {
          window.confirm(
            "内部エラーが発生したため、決済処理を開始できませんでした"
          );
          return;
        }

        const email = refCardEmailInput.current.value;
        const name = refCardNameInput.current.value;

        if (email.length === 0 || name.length === 0) {
          setErrorMessage(ERROR_REQUIRED_NAME_AND_EMAIL);
          return;
        }

        const token = await payjp.createToken(numberElement, {
          "card[email]": email,
          "card[name]": name,
        });
        if (!implementsPayjpToken(token)) {
          setErrorMessage(ERROR_CREATE_TOKEN);
          return;
        }
        if (!AVAILABLE_CARD_BLANDS.includes(token.card.brand)) {
          setErrorMessage(ERROR_UNSUPPORTED_CARD_BLAND);
          return;
        }
        targetCardId = token.card.id;

        if (customer == null) {
          const res = await postPayjpCustomer(token.id);
          newCustomerId = res.data.customerId;
        } else if (
          customer.cards.some(
            (card) => card.fingerprint === token.card.fingerprint
          )
        ) {
          setErrorMessage(ERROR_CARD_IS_ALREADY_REGISTERD);
          return;
        } else {
          await postPayjpCustomerCard(token.id);
        }
      } else if (displayNameAndEmailForm) {
        if (
          refUpdateCardEmailInput.current == null ||
          refUpdateCardNameInput.current == null
        ) {
          window.confirm(
            "内部エラーが発生したため、決済処理を開始できませんでした"
          );
          return;
        }

        const email = refUpdateCardEmailInput.current.value;
        const name = refUpdateCardNameInput.current.value;

        if (email.length === 0 || name.length === 0) {
          setErrorMessage(ERROR_REQUIRED_NAME_AND_EMAIL);
          return;
        }

        await patchPayjpCustomerCard(targetCardId, { name, email }).catch(
          (err) => {
            window.alert("カード情報の更新に失敗しました。");
            throw err;
          }
        );
      }

      if (targetCardId === "new") {
        throw "invalid target card id";
      }

      if (gift) {
        const resp = await postBuyGiftWithThreeDSecure(projectId, {
          price,
          cardId: targetCardId,
        }).catch((err) => {
          if (cardId === "new") {
            deletePayjpCustomerCard(targetCardId);
          }
          throw err;
        });

        redirectToThreeDSecure(resp.data.chargeId, resp.data.backUrlJws);
      } else {
        const resp = await postBuyWithThreeDSecure(projectId, {
          price,
          cardId: targetCardId,
          couponId: coupon?.id,
        }).catch((err) => {
          if (cardId === "new") {
            deletePayjpCustomerCard(targetCardId);
          }
          throw err;
        });

        redirectToThreeDSecure(resp.data.chargeId, resp.data.backUrlJws);
      }
    } catch (err: unknown) {
      if (axios.isAxiosError(err) && err.response?.status === 409) {
        setErrorMessage(ERROR_PRICE_CONFLICT);
      } else {
        setErrorMessage(ERROR_UNKNOWN);
      }

      if (newCustomerId) setCustomer({ customerId: newCustomerId, cards: [] });
    }
  };

  const handleChangeCards = (cards: MaskedCard[]) => {
    if (customer) {
      setCustomer({ customerId: customer.customerId, cards });
    }
  };

  const applyFreeCoupon = useCallback(
    (coupon: Coupon) => {
      postGetByCoupon(projectId, coupon.id)
        .then(() => {
          alert("シナチケが適用されました");
          setErrorMessage(undefined);
          dispatchReload();
          setSuccess(true);
        })
        .catch((err) => {
          if (axios.isAxiosError(err) && err.response?.status === 400) {
            alert(`シナチケ "${coupon.id}" を使用できませんでした`);
          } else {
            alert(
              `シナチケの使用に失敗しました。通信状況が正常かご確認ください。`
            );
          }
        });
    },
    [projectId, setErrorMessage, dispatchReload, setSuccess]
  );

  const handleChangeCoupon = useCallback(
    (coupon: Coupon) => {
      setCoupon(coupon);

      if (coupon.coupon_type === "free") {
        applyFreeCoupon(coupon);
        return;
      }

      alert("シナチケが適用されました。お支払い金額が更新されます");
    },
    [setCoupon, applyFreeCoupon]
  );

  let basePrice: number | undefined = undefined;
  if (coupon) {
    basePrice = price;
    price = priceWithCoupon(price, coupon);
  }

  if (success) {
    return (
      <div className={styles.container}>
        <h2 className={styles.ttl}>クレジットカード決済</h2>
        <div className={styles.box}>
          <PriceCard
            projectName={projectName}
            categoryName={categoryName}
            price={price}
            priceBeforeDiscount={basePrice}
          />
          <p className={styles.txt}>
            購入が完了しました。
            <br className={styles.spi} />
            ご利用ありがとうございました。
          </p>
          <ShareButtons projectName={projectName} />
          <button className={styles.btnSecondary} onClick={onClose}>
            決済画面を閉じる
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>クレジットカード決済</h2>
      <div className={styles.box}>
        <PriceCard
          projectName={projectName}
          categoryName={categoryName}
          price={price}
          priceBeforeDiscount={basePrice}
        />
        <CouponArea
          projectId={projectId}
          onChange={handleChangeCoupon}
          onChangeDirty={setDirtyCouponInput}
          disabled={gift}
        />
        <SelectCard
          cards={customer?.cards}
          cardId={cardId}
          onChange={setCardId}
          onChangeCards={handleChangeCards}
        />
        {cardId === "new" && (
          <>
            <ul className={styles.cards}>
              <li className={styles.icon}>
                <img src={IconVisa} alt="Visa" />
              </li>
              <li className={styles.icon}>
                <img src={IconMaster} alt="Master Card" />
              </li>
              <li className={styles.icon}>
                <img src={IconJCB} alt="JCB" />
              </li>
              <li className={styles.icon}>
                <img src={IconAmex} alt="Amex" />
              </li>
              <li className={styles.icon}>
                <img src={IconDiscover} alt="Discover" />
              </li>
            </ul>
            <div className={styles.inputArea}>
              <label className={styles.label}>カード番号</label>
              <div id="numberElement" className={styles.inputNumber}></div>
            </div>
            <div className={styles.flex}>
              <div className={styles.left}>
                <label className={styles.label}>月/年</label>
                <div id="expiryElement" className={styles.inputDate}></div>
              </div>
              <div className={styles.right}>
                <label className={styles.label}>セキュリティコード</label>
                <div id="cvcElement" className={styles.inputCvc}></div>
              </div>
            </div>
            <div className={styles.inputArea}>
              <label className={styles.label}>カードの名義人</label>
              <input className={styles.inputDate} ref={refCardNameInput} />
            </div>
            <div className={styles.inputArea}>
              <label className={styles.label}>メールアドレス</label>
              <input
                className={styles.inputDate}
                type="email"
                ref={refCardEmailInput}
                defaultValue={defaultEmail}
              />
            </div>
          </>
        )}
        {displayNameAndEmailForm && selectedCard && (
          <>
            <h4 className={styles.subttlWithMargin}>追加情報の入力</h4>
            <div className={styles.inputArea}>
              <label className={styles.label}>カード情報</label>
              <p>
                {selectedCard.brand} 下4桁 {selectedCard.last4}{" "}
                <span className={styles.expiry}>
                  有効期限 {cardExpiryToString(selectedCard)}
                </span>
              </p>
            </div>
            <div className={styles.inputArea}>
              <label className={styles.label}>カードの名義人</label>
              <input
                className={styles.inputDate}
                ref={refUpdateCardNameInput}
              />
            </div>
            <div className={styles.inputArea}>
              <label className={styles.label}>メールアドレス</label>
              <input
                className={styles.inputDate}
                type="email"
                ref={refUpdateCardEmailInput}
                defaultValue={defaultEmail}
              />
            </div>
          </>
        )}
        {errorMessage && (
          <p className={styles.txt} data-checked="true">
            {errorMessage}
          </p>
        )}
        <div className={styles.btnWrap}>
          <BtnWithProcessing
            labelProsessing="処理中"
            fullWidth
            onClick={handleBuy}
          >
            {gift ? "ギフトを購入する" : "購入する"}
          </BtnWithProcessing>
        </div>
        {gift && (
          <p className={styles.warning}>
            ※ギフトが受け取られなかった場合についても、返金対応は致しかねますので予めご了承ください。
          </p>
        )}
      </div>
    </div>
  );
};

export default ModalPayment;
