import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import styles from "stories/pages/Item/Item.module.css";
import itemStyles from "./Item.module.css";
import ItemDescription from "stories/pages/Item/ItemDescription/ItemDescription";
import ItemContents from "stories/pages/Item/ItemContents/ItemContents";
import ItemShare from "stories/pages/Item/ItemShare/ItemShare";
import ItemSide from "stories/pages/Item/ItemSide/ItemSide";
import { useParams } from "react-router";
import { NotFoundBody } from "pages/NotFound";
import { SpinnerCenter } from "components/Spinner";
import { AuthContext, AuthState } from "components/AuthProvider";
import { getProjectWithReleases, ProjectWithReleases } from "apiv1/project";
import { logEvent } from "@firebase/analytics";
import { analytics } from "initialize";
import { useCookies } from "react-cookie";
import { postProjectViewed } from "apiv1/project";
import DefaultLayout from "components/DefaultLayout";
import { useModal } from "components/Modal";
import ModalItemAttachment from "stories/components/Modal/ModalItemAttachment/ModalItemAttachment";
import ModalPayment from "./ModalPayment";
import ItemFloatingAction from "stories/pages/Item/ItemFloatingAction/ItemFloatingAction";
import { resetDocumentTitle } from "utils/title";
import ModalPaymentSuccess from "./ModalPayment/ModalPaymentSuccess";

const checkVisibility = (
  user: AuthState,
  itemData: ProjectWithReleases
): { allContentsDisabled: boolean; attachmentDisabled: boolean } => {
  const noAttachments = itemData.project.attachments.items.length === 0;

  if (user.authed && user.uid === itemData.project.owner_id) {
    return {
      allContentsDisabled: false,
      attachmentDisabled: noAttachments,
    };
  }

  const allContentsDisabled =
    itemData.project.fee_type === "all_contents" && itemData.purchase == null;

  const attachmentDisabled =
    noAttachments ||
    ((itemData.project.fee_type === "all_contents" ||
      itemData.project.fee_type === "attachments_only") &&
      itemData.purchase == null);

  return { allContentsDisabled, attachmentDisabled };
};

type ItemData = ProjectWithReleases | "loading" | "notfound";

const useAnalyticsViewItem = (itemData: ItemData) => {
  const previous = useRef<string | null>(null); // For the avoid duplication
  useEffect(() => {
    if (!itemData || itemData === "loading" || itemData === "notfound") return;
    if (previous.current !== itemData.project.id) {
      logEvent(analytics, "view_item", {
        items: [
          {
            item_id: itemData.project.id,
            item_name: itemData.project.name,
            item_category: itemData.category.name,
          },
        ],
      });
      previous.current = itemData.project.id;
    }
  }, [itemData]);
};

const useProjectViewed = (projectId: string) => {
  const [cookies, setCookie] = useCookies<string, { [x: string]: any }>([]);
  useEffect(() => {
    const cookieName = `visited-${projectId}`;
    if (cookies[cookieName] == null) {
      postProjectViewed(projectId).catch(() => {
        /* ignore error */
      });
      setCookie(cookieName, "1", {
        maxAge: 24 * 60 * 60, // 1 day
        path: `/`,
        secure: true,
      });
    }
  }, [projectId, setCookie, cookies]);
};

export const ItemView: React.VFC = () => {
  const user = useContext(AuthContext);
  const { projectId } = useParams<{ projectId: string }>();
  const [itemData, setItemData] = useState<ItemData>("loading");
  const [reloadTrigger, setReloadTrigger] = useState<number>(0);
  const [open, onOpen, onClose] = useModal();
  const [openPayment, onOpenPayment, onClosePayment] = useModal();
  const [isGift, setIsGift] = useState(false);

  useAnalyticsViewItem(itemData);
  useProjectViewed(projectId);
  useEffect(() => {
    getProjectWithReleases(projectId)
      .then((resp) => {
        setItemData(resp.data);
      })
      .catch(() => setItemData("notfound"));
  }, [projectId, user, reloadTrigger]);

  useEffect(() => {
    if (itemData === "loading" || itemData === "notfound") {
      return;
    }

    window.document.title = itemData.project.name;
    return resetDocumentTitle;
  }, [itemData]);

  const dispatchReload = () => setReloadTrigger(reloadTrigger + 1);

  const onOpenBuy = useCallback(() => {
    if (!user.authed) {
      alert("作品を購入するにはログインしてください");
      return;
    }

    if (itemData === "loading" || itemData === "notfound") {
      return;
    }

    if (user.uid === itemData.project.owner_id) {
      alert("自分の作品は購入できません");
      return;
    }

    setIsGift(false);
    onOpenPayment();
  }, [onOpenPayment, setIsGift, user, itemData]);

  const onOpenGift = useCallback(() => {
    if (!user.authed) {
      alert("ギフトを購入するにはログインしてください");
      return;
    }

    if (itemData === "loading" || itemData === "notfound") {
      return;
    }

    if (user.uid === itemData.project.owner_id) {
      alert("自分の作品のギフトは購入できません");
      return;
    }

    setIsGift(true);
    onOpenPayment();
  }, [onOpenPayment, setIsGift, user, itemData]);

  if (itemData === "notfound") {
    return <NotFoundBody />;
  } else if (itemData === "loading") {
    return (
      <div className={itemStyles.loading}>
        <SpinnerCenter />
      </div>
    );
  }

  if (itemData.project.released_at == null || itemData.releases.length === 0) {
    return <NotFoundBody />;
  }

  const release = itemData.releases[0];
  const booklet = release.booklet_infos.booklets[0];
  const updatedAt = release.created_at;
  const bookletURL = `/projects/${itemData.project.id}/rev/${release.rev}/booklets/${booklet.id}`;
  const purchased = itemData.purchase != null;
  const { allContentsDisabled, attachmentDisabled } = checkVisibility(
    user,
    itemData
  );

  const copyright_notice =
    itemData.project.copyright_notice.length > 0
      ? itemData.project.copyright_notice
      : itemData.category.copyright_notice;

  return (
    <div className={styles.main}>
      <ItemDescription
        author={itemData.project.author}
        userName={itemData.ownerProfile.displayName}
        ownerId={itemData.project.owner_id}
        thumbnail={itemData.project.cover_url}
        categoryName={itemData.category.name}
        categorySlug={itemData.category.slug}
        categoryId={itemData.category.id}
        title={itemData.project.name}
        views={itemData.project.watch_count}
        playerMin={itemData.project.players_min}
        playerMax={itemData.project.players_max}
        playtimeMin={itemData.project.playing_hour_min}
        playtimeMax={itemData.project.playing_hour_max}
        tags={itemData.project.tags}
        description={itemData.project.description}
        visibility={itemData.project.visibility}
      />
      <div className={styles.side}>
        <ItemSide
          projectId={itemData.project.id}
          projectName={itemData.project.name}
          feeType={itemData.project.fee_type}
          price={itemData.project.price}
          licenseId={itemData.project.license_id}
          updatedAt={updatedAt}
          bookmarked={itemData.bookmarked}
          bookletURL={bookletURL}
          purchased={purchased}
          onOpenAttach={onOpen}
          onOpenBuy={onOpenBuy}
          onOpenGift={onOpenGift}
          disabledBookletLink={allContentsDisabled}
          disabledAttachButton={attachmentDisabled}
        />
      </div>
      <ItemContents
        release={itemData.releases[0]}
        attachments={itemData.project.attachments.items}
        copyrightNotice={copyright_notice}
        disabledBookletLink={allContentsDisabled}
      />
      <div className={styles.spb}>
        <ItemShare projectName={itemData.project.name} />
      </div>
      <ItemFloatingAction
        variant={allContentsDisabled ? "buy" : "read"}
        bookletURL={bookletURL}
        onClickBuy={onOpenBuy}
      />
      <ModalItemAttachment
        open={open}
        onClose={onClose}
        projectId={itemData.project.id}
        attachments={itemData.project.attachments.items}
      />
      <ModalPayment
        open={openPayment}
        purchased={purchased}
        gift={isGift}
        price={itemData.project.price || 0}
        feeType={itemData.project.fee_type}
        projectId={projectId}
        projectName={itemData.project.name}
        categoryName={itemData.category.name}
        dispatchReload={dispatchReload}
        onClose={onClosePayment}
      />
      <ModalPaymentSuccess
        projectId={projectId}
        projectName={itemData.project.name}
        thumbnail={itemData.project.cover_url}
        categoryName={itemData.category.name}
        dispatchReloadProject={dispatchReload}
      />
    </div>
  );
};

const ItemPage = () => {
  return (
    <DefaultLayout>
      <ItemView />
    </DefaultLayout>
  );
};

export default ItemPage;
